import React from "react"
import { graphql } from "gatsby"
import SimplePage from "../components/page/SimplePage"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <SimplePage title={frontmatter.title}>
      <div className="markdown">
        <div className="" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </SimplePage>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
